<script setup>
const { data: stops, pending } = useLazyAsyncData(
  'stop-points',
  () => {
    return $fetch('/api/stops')
  },
  {
    server: false,
  },
)
</script>

<template>
  <article>
    <Head>
      <Title>{{ 'Arrêts – VMCV Timetable' }}</Title>
    </Head>
    <AppArticleHeader :pending="pending">
      {{ 'Liste des Arrêts' }}
    </AppArticleHeader>
    <section>
      <StopList :pending="pending" :stops="stops" />
    </section>
  </article>
</template>
